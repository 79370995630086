import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class WatcherService {

    private headerUpdateSource = new BehaviorSubject<any>({ data: { data: { user: {} } } });
    onHeaderUpdate = this.headerUpdateSource.asObservable();

    private headerViewSource = new BehaviorSubject<any>('');
    onHeaderViewUpdate = this.headerViewSource.asObservable();
    private headerSignUpSource = new BehaviorSubject<any>('');
    onHeaderSignUpCodeUpdate = this.headerSignUpSource.asObservable();

    private announcementBannerStatusSource = new BehaviorSubject<Boolean>(false);
    onAnnouncementBannerStatusUpdate = this.announcementBannerStatusSource.asObservable();

    private announcementBannerHeightSource = new BehaviorSubject<Number>(0);
    onAnnouncementBannerHeightUpdate = this.announcementBannerHeightSource.asObservable();

    private hostStoreShoppingCartSource = new BehaviorSubject<any>(0);
    onHostStoreShoppingCartUpdate = this.hostStoreShoppingCartSource.asObservable();

    private langSubject = new BehaviorSubject<string>('en');
    private loginUpdateSource = new BehaviorSubject<string>('init');
    onLoginUpdate = this.loginUpdateSource.asObservable();

    private tokenUpdateSource = new BehaviorSubject<string>('');
    onTokenUpdate = this.tokenUpdateSource.asObservable();



    setLang(lang: string) {
        switch (lang) {
            case 'ja':
                lang = 'ja'
                break;
            case 'ms':
                lang = 'ms'
                break;
            case 'nl':
                lang = 'nl'
                break;
            case 'sv':
                lang = 'sv'
                break;
            case 'no':
                lang = 'no'
                break;
            case 'pt':
                lang = 'pt'
                break;
            case 'ru':
                lang = 'ru'
                break;
            case 'en':
                lang = 'en'
                break;
            case 'fr':
                lang = 'fr'
                break
            case 'zh-CN':
                lang = 'zh-CN'
                break
            case 'de':
                lang = 'de'
                break
            case 'it':
                lang = 'it'
                break
            case 'es':
                lang = 'es'
                break
            case 'he':
                lang = 'he'
                break
            case 'ar':
                lang = 'ar'
                break
            case 'id':
                lang = 'id'
                break
            case 'pt':
                lang = 'pt'
                break
            default:
                lang = 'en'
                break;
        }
        this.langSubject.next(lang)
    }
    getLang() {
        return this.langSubject.asObservable();
    }

    private hostCountriesSubject = new BehaviorSubject<any>([]);

    setHostCountriesResponse(response) {
        this.hostCountriesSubject.next(response)
    }

    getHostCountriesResponse() {
        return this.hostCountriesSubject.asObservable();
    }


    private purchasesResponseSubject = new BehaviorSubject<any>([]);
    setPurchasesResponse(response) {
        this.purchasesResponseSubject.next(response)
    }
    getPurchasesResponse() {
        return this.purchasesResponseSubject.asObservable();
    }

    constructor() {
        let lang = navigator.language.slice(0, 2).toLowerCase();
        this.setLang(lang)
    }

    updateHeaderData(message: object) {
        this.headerUpdateSource.next(message);
    }

    whichHeader(view: String) {
        this.headerViewSource.next(view);
    }
    hellofromDiscountCode(code: String) {
        this.headerSignUpSource.next(code)
    }
    activeAnnouncementBanner(status: Boolean) {
        this.announcementBannerStatusSource.next(status);
    }
    announcementBannerHeightUpdate(height: Number) {
        this.announcementBannerHeightSource.next(height);
    }
    isUserLogin(status: string) {
        this.loginUpdateSource.next(status)
    }
    updateUserToken(token: string) {

        if (token.length > 0) {
            this.tokenUpdateSource.next(token);
            localStorage.setItem('token', token)
        } else {
            localStorage.removeItem('token')

        }

    }
    messagesNotificaitonsData = [];
    getMessagesNotificationsData() {
        return this.messagesNotificaitonsData;
    }
    setMessagesNotificationsData(data) {
        this.messagesNotificaitonsData = data;
    }
    hostStoreShoppingCart(data: any) {
        this.hostStoreShoppingCartSource.next(data)
    }
}
<ngx-ui-loader></ngx-ui-loader>
<div class="app-shippn-header" [ngStyle]="{'min-height': headerWithoutMastercardHeight}" *ngIf="!isShopNowActive">
    <app-header></app-header>
</div>
<div class="instant-calculator-discount-count-down-wrapper" *ngIf="!timerFinished && timerStart"
    style="background-color: #b00; color: #fff;">
    <h5><span>%10 discount applied, create your order before it expires!</span> <span class="remaining-time-span"
            style="min-width: 50px;">{{
            remainingTime?.minutes | number: '2.0-0' }} : {{ remainingTime?.seconds | number: '2.0-0' }}</span></h5>
</div>
<div class="app-shippn-router-outlet">
    <router-outlet></router-outlet>
</div>
<app-footer *ngIf="!isShopNowActive"></app-footer>
<div class="mini-popup" id="popupForBFMPurchase" *ngIf="!hideMiniPopItems">
    <div *ngIf="minipopupMessage == 1">
        <span translate="no">{{minipopupShopperName}}</span> just took an address from <br />
        <span style="display: flex; flex-direction: row; align-items: center; height: 20px;"><img
                src="https://www.shippn.com/assets/img/flags/{{popupBFM?.country_from_flag}}"
                alt="{{popupBFM?.country_from_name}}" width="20px" height="20px" fetchpriority="low" loading="lazy"
                async>
            <span style="padding-left: 2px;">{{popupBFM?.country_from_name}}</span></span>
    </div>
    <div *ngIf="minipopupMessage == 2">
        <span translate="no">{{minipopupShopperName}}</span>'s package has been delivered from <img
            src="https://www.shippn.com/assets/img/flags/{{popupBFM?.country_from_flag}}"
            alt="{{popupBFM?.country_from_name}}" width="20px" height="20px" fetchpriority="low" loading="lazy" async>
        {{popupBFM?.country_from_name}} to <img
            src="https://www.shippn.com/assets/img/flags/{{popupBFM?.country_to_flag}}"
            alt="{{popupBFM?.country_to_name}}" width="20px" height="20px" fetchpriority="low" loading="lazy" async>
        {{popupBFM?.country_to_name}}
    </div>
    <div *ngIf="minipopupMessage == 3">
        <span translate="no">{{minipopupShopperName}}</span> just placed an order {{popupBFM?.title}} from <br />
        <img src="https://www.shippn.com/assets/img/flags/{{popupBFM?.country_from_flag}}"
            alt="{{popupBFM?.country_from_name}}" width="20px" height="20px" fetchpriority="low" loading="lazy" async>
        {{popupBFM?.country_from_name}} to <img
            src="https://www.shippn.com/assets/img/flags/{{popupBFM?.country_to_flag}}"
            alt="{{popupBFM?.country_to_name}}" width="20px" height="20px" fetchpriority="low" loading="lazy" async>
        {{popupBFM?.country_to_name}}
    </div>
    <div *ngIf="minipopupMessage == 4">
        A Host just sent a package from <br /><img
            src="https://www.shippn.com/assets/img/flags/{{popupBFM?.country_from_flag}}"
            alt="{{popupBFM?.country_from_name}}" width="20px" height="20px" fetchpriority="low" loading="lazy" async>
        {{popupBFM?.country_from_name}} to <img
            src="https://www.shippn.com/assets/img/flags/{{popupBFM?.country_to_flag}}"
            alt="{{popupBFM?.country_to_name}}" width="20px" height="20px" fetchpriority="low" loading="lazy" async>
        {{popupBFM?.country_to_name}}
    </div>
</div>
<button class="btn shippn-from-to-area-go-btn" id="getNewAdressModalTriggerSticky" data-toggle="modal"
    data-target="#getNewAdressModal" style="display:none!important;">
</button>
<div class="modal fade " id="getNewAdressModal" tabindex="-1" aria-labelledby="Get New Address Modal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div style="background: #222222;" class="modal-header">
                <div style="display: inline-flex;align-items: center;">
                    <i class="fas fa-map-marker-alt" style="font-size: 24px; color: #fff;"></i>
                    <h5 style="color: #fff;padding-left: 15px;" class="modal-title" id="getNewAddressModalTitle">
                        Get New Address </h5>
                </div>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span style="color: white;" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="card-body" style="padding: 5px;">

                            <div class="shop_country_from"
                                style="display: flex; flex-direction: row;align-items: center;">
                                <h5 style="font-size: 14px;font-weight: 900;margin-bottom: 0;">
                                    Shop from
                                </h5>
                                <img *ngIf="getNewAdressCountryFrom" loading="lazy" fetchpriority="low"
                                    src="https://www.shippn.com/assets/img/flags/{{getNewAdressCountryFrom?.country_flag}}"
                                    width="24px" height="24px" style="margin: 0 5px;" alt="Country Flag">
                                <h5 style="font-size: 14px;font-weight: 900;margin-bottom: 0;">
                                    {{getNewAdressCountryFrom?.name}}
                                </h5>
                            </div>
                            <div class="row">
                                <div style="margin-top: 15px;" class="col-md-12">
                                    <div style="display: flex;align-items: flex-end;">
                                        <div class="select--wrapper"
                                            style="width: calc(100% - 10px); margin-right: 10px;">
                                            <i class="fas fa-chevron-down"
                                                style="position: absolute; right: 14px!important; top: 20px!important;float: right; color: #222222; font-size: 12px;"
                                                aria-hidden="true"></i>
                                            <select (change)="getNewAddressCountry(countryFrom)"
                                                [(ngModel)]="countryFrom"
                                                style="margin-top: 5px; margin-bottom: 0px; width: 100%; border-radius: 5px;padding: 12px;height: 42px;margin-right: 10px;font-size: 13px;">
                                                <option *ngFor="let countriesWithHost of hostCountries;"
                                                    value="{{countriesWithHost?.code}}">
                                                    <span
                                                        *ngIf="countriesWithHost.code != 'TR' && countriesWithHost.name != 'Turkey'">{{countriesWithHost.name}}</span>
                                                    <span
                                                        *ngIf="countriesWithHost.code == 'TR' || countriesWithHost.name == 'Turkey'" translate="no">Türkiye</span>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin-top: 10px;" class="col-md-12">
                                    <p style="margin-bottom: 0px;font-size: 14px;font-weight: normal;">
                                        Get a local address from hosts in {{hostCountries?.length}}
                                        countries
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a target="_blank" [routerLink]="['/', lang,'shop-from', countryFrom.toLowerCase()]"
                    style="width: 85px;font-size: 14px;height: 42px;background: #fc7137 !important;color: white !important;font-weight: bold;border-radius: 5px;margin-top: 0px !important;align-items: center;display: grid;"
                    class="btn shippn-from-to-area-go-btn" (click)="closeShopFromModal()">GO</a>
            </div>

        </div>
    </div>
</div>
<button data-toggle="modal" id="createOrderModalTriggerSticky" data-target="#createOrderModal"
    style="display: none!important;" class="btn shippn-from-to-area-go-btn">CREATE ORDER</button>
<!-- Create Host Order Modal -->
<div class="modal fade" id="createOrderModal" tabindex="-1" aria-labelledby="Create Order Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div style="background: #222222" class="modal-header">
                <h5 style="color: white; padding-left: 15px" class="modal-title" id="createOrderModalTitle">
                    Create Order
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span style="color: white" aria-hidden="true">&times;</span>
                </button>
            </div>

            <form ngNativeValidate [formGroup]="orderCreateForm">
                <div style="padding-left: 50px; padding-right: 50px" class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <p style="
                    margin-bottom: 0px;
                    font-size: 16px;
                    font-weight: 300;
                    text-align: left;
                  ">
                                Please enter either the order number or the shopper name if
                                available on the shipping label on the box you have received.
                                (Only the shopper first name or the order number is needed)
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div style="margin-top: 10px" class="col-12 col-lg-12">
                            <p style="
                    color: black;
                    text-align: left;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 5px;
                  ">
                                Shopper
                            </p>
                            <input type="text" formControlName="shopperName" [ngClass]="{
                    'shippn-border-red':
                      orderCreateForm.get('shopperName').touched &&
                      orderCreateForm.get('shopperName').invalid
                  }" placeholder="Shopper Name & Surname" class="form-control form-control-sm shippn-input"
                                translate="no" />
                        </div>
                    </div>

                    <div class="row">
                        <div style="margin-top: 10px" class="col-12 col-lg-12">
                            <p style="
                    color: black;
                    text-align: left;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 5px;
                  ">
                                Order Number
                            </p>
                            <input type="text" formControlName="orderNumber" [ngClass]="{
                    'shippn-border-red':
                      orderCreateForm.get('orderNumber').touched &&
                      orderCreateForm.get('orderNumber').invalid
                  }" placeholder="Order Number" class="form-control form-control-sm shippn-input" />
                        </div>
                    </div>

                    <div class="row">
                        <div style="margin-top: 10px" class="col-md-12">
                            <button style="
                    width: 170px;
                    font-size: 16px;
                    font-weight: normal;
                    height: 38px;
                  " (click)="searchAndCreateOrder()" class="btn shippn-from-to-area-go-btn">
                                Search and Create
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>